// Add this polyfill to fix a warning with Redux
import 'symbol-observable';
import { createInertiaApp } from '@inertiajs/react';
// import { createElement } from 'react'
import { hydrateRoot } from 'react-dom/client'
// import { createRoot } from 'react-dom/client'

createInertiaApp({
  // Set default page title
  // see https://inertia-rails.netlify.app/guide/title-and-meta
  //
  // title: title => title ? `${title} - App` : 'App',

  // Disable progress bar
  //
  // see https://inertia-rails.netlify.app/guide/progress-indicators
  // progress: false,

  resolve: (name) => {
    // Eager load
    // const pages = import.meta.glob('../pages/**/*.tsx', { eager: true })
    // return pages[`../pages/${name}.tsx`];

    // Code Splitting
    const pages = import.meta.glob('../pages/**/*.tsx')
    return pages[`../pages/${name}.tsx`]();
  },

  setup({ el, App, props }) {
    // if(import.meta.env.DEV) {
      // const root = createRoot(el)
      // root.render(createElement(App, props) as unknown as any)
    // } else {
      hydrateRoot(el as unknown as Element, <App {...props} /> as unknown as any) 
    // }
  },
});
